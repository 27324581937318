<script setup lang="ts">
import { inject, computed, InputHTMLAttributes, useSlots, useAttrs, toRef, ref } from 'vue';
import { useField } from 'vee-validate';
import { IIconProps } from '../ApzIcon/ApzIcon.vue';

export interface IInputProps extends /* @vue-ignore */ InputHTMLAttributes {
  name: string;
  type?: string;
  modelValue?: string;
  label?: string;
  labelFor?: string;
  labelClass?: string;
  placeholder?: string;
  variant?: 'default' | 'outline' | 'flat';
  icon?: IIconProps['icon'];
  iconRight?: boolean;
  iconClass?: string;
  wrapperClass?: string;
  error?: string;
  help?: string;
  helpClass?: string;
  row?: number;
  readonly?: boolean;
  required?: boolean;
  standalone?: boolean;
  getInputValue?: (value: string | undefined) => string;
}

export interface IInputEmits {
  (e: 'update:modelValue', value: string): void;
  (e: 'click'): void;
  (e: 'blur', payload: FocusEvent): void;
}

const props = withDefaults(defineProps<IInputProps>(), {
  variant: 'default',
  type: 'text',
  row: 3,
});
const emit = defineEmits<IInputEmits>();

const isGrouped = inject<boolean>('isGrouped', false);
const name = toRef(props, 'name');

const input = ref<HTMLInputElement | null>(null);

const slots = useSlots();
const attrs = useAttrs();
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(name, undefined, { standalone: props.standalone, initialValue: props.modelValue, syncVModel: true });

const statusClasses = computed(() => {
  if ((props.error || errorMessage.value) && !isGrouped) {
    if (props.variant === 'flat') {
      return 'ring-1 !ring-danger';
    }

    return 'border-danger';
  }

  return 'border-gray-lightest hover:border-gray-dark';
});

const variantClasses = computed(() => {
  if (props.variant === 'outline') {
    return 'bg-white border rounded pl-10 disabled:bg-gray-lightest disabled:border-gray-lightest';
  }

  if (props.variant === 'flat') {
    return 'bg-whiter border-0 rounded pl-10 focus:outline-none focus:ring-gray-darkest disabled:bg-gray-lightest';
  }

  return 'bg-transparent border-0 border-b focus:outline-none focus:ring-0 focus:border-gray-darkest';
});

const computedIcon = computed(() => {
  return typeof props.icon === 'string' ? (['fal', props.icon] as IIconProps['icon']) : props.icon;
});

const hasLeftIcon = computed(() => {
  return (props.icon && !props.iconRight) || slots['icon-left'];
});

const hasRightIcon = computed(() => {
  return (props.icon && props.iconRight) || slots['icon-right'];
});

const inputAttrs = computed(() => {
  return {
    ...attrs,
    value: inputValue.value,
    id: props.labelFor ?? props.name,
    type: props.type,
    name: props.name,
    placeholder: props.placeholder,
    readonly: props.readonly,
  } as InputHTMLAttributes;
});

function onInput(e: unknown, shouldValidate = true) {
  handleChange(props.getInputValue?.((e as any).target.value) ?? e, shouldValidate);
  emit('update:modelValue', inputValue.value);
}

function onBlur(e: FocusEvent) {
  handleBlur(e);
  emit('blur', e);
}

defineExpose({ $el: input, focus: () => input.value?.focus() });
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div :class="wrapperClass">
    <label
      v-if="label && !isGrouped"
      :for="labelFor ?? name"
      class="block text-small mb-5"
      :class="[labelClass]"
    >
      <slot name="label">
        {{ label }}

        <span
          v-if="required"
          class="text-primary relative -left-3"
        >
          *
        </span>
      </slot>
    </label>

    <div
      :class="[{ 'pointer-events-none': $attrs.disabled }, 'relative']"
      @click="emit('click')"
    >
      <input
        v-if="type !== 'textarea'"
        ref="input"
        v-bind="inputAttrs"
        :class="[
          variantClasses,
          statusClasses,
          $attrs.class,
          { 'pl-35': hasLeftIcon },
          'disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none disabled:placeholder-gray-dark peer block w-full h-35 text-base font-bold placeholder-gray-light p-0',
          (variant === 'outline' || variant === 'flat') && 'placeholder:font-normal',
        ]"
        @input="onInput"
        @blur="onBlur"
      />

      <textarea
        v-else
        :id="labelFor ?? name"
        type="textarea"
        :name="name"
        :value="inputValue"
        :placeholder="placeholder"
        :class="[
          variantClasses,
          statusClasses,
          $attrs.class,
          { 'pl-35': hasLeftIcon },
          'peer block w-full text-base placeholder-gray-light p-0 border-0 disabled:opacity-50 disabled:cursor-not-allowed placeholder:font-normal',
        ]"
        :rows="row"
        @input="onInput"
        @blur="handleBlur"
      />

      <span
        v-if="hasLeftIcon"
        class="text-icon absolute bottom-0 left-0 h-35 w-30 flex items-center justify-center pointer-events-none peer-focus:text-gray-darkest peer-disabled:opacity-30"
        :class="iconClass"
      >
        <slot name="icon-left">
          <ApzIcon :icon="computedIcon" />
        </slot>
      </span>

      <span
        v-if="hasRightIcon"
        :class="[
          'text-icon absolute bottom-1/2 transform translate-y-1/2 right-0 h-35 w-fit flex items-center justify-center peer-disabled:opacity-30',
          iconClass,
          (variant === 'outline' || variant === 'flat') && 'right-10',
        ]"
      >
        <slot name="icon-right">
          <ApzIcon :icon="computedIcon" />
        </slot>
      </span>
    </div>

    <p
      v-if="(error || errorMessage || help) && !isGrouped"
      class="text-small mt-10"
      :class="[error || errorMessage ? 'text-danger' : 'text-gray-dark', helpClass]"
    >
      <slot name="message">
        {{ error || errorMessage || help }}
      </slot>
    </p>
  </div>
</template>
